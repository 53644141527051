import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import Authentication from '@/domain/models/Authentication';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import UserGroupModuleRepository from '@/repositories/UserGroupModuleRepository';

export default class LoginHelper {
  public static async loadAvailableModules(): Promise<string> {
    const userGroupModuleRepository = new UserGroupModuleRepository();
    const authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

    const allModules = await userGroupModuleRepository.getAll(
      Number(authenticationModule.user.company_group_id),
      Number(authenticationModule.user.user_group_id),
    );

    const availableModules = allModules.filter((module) => module.isActive && module.isPurchased);

    authenticationModule.setAvailableModules(availableModules);

    if (availableModules.length == 1) {
      authenticationModule.setCurrentModule(availableModules[0]);
    }

    if (authenticationModule.currentModule != null) {
      return authenticationModule.currentModule.initialRoute;
    }

    return 'Modules';
  }

  public static setSessionPermissions(authData: Authentication): void {
    Vue.prototype.$session.set('token', authData.token);
    Vue.prototype.$session.set('name', authData.name);
    Vue.prototype.$session.set('email', authData.email);
    Vue.prototype.$session.set('status', authData.status);
    Vue.prototype.$session.set('is_admin', authData.isAdmin);
    Vue.prototype.$session.set('user_group_id', authData.userGroupId);
    Vue.prototype.$session.set('user_group_name', authData.userGroupName);
    Vue.prototype.$session.set('user_type', authData.userType);
    Vue.prototype.$session.set('company_group_id', authData.companyGroupId);
    Vue.prototype.$session.set('company_group_name', authData.companyGroupName);
    Vue.prototype.$session.set('company_group_image', authData.companyGroupImage);
    Vue.prototype.$session.set('user_gravatar', authData.userGravatar);
    Vue.prototype.$session.set('data_block', authData.block);
    Vue.prototype.$session.set('url_powerbi_dashboard', authData.urlPowerbiDashboard);
    Vue.prototype.$session.set('url_powerbi_dashboard_darkmode', authData.urlPowerbiDashboardDarkmode);
    Vue.prototype.$session.set('url_powerbi', authData.urlPowerbi);
    Vue.prototype.$session.set('use_ncc', authData.useNcc);
    Vue.prototype.$session.set('use_webhook', authData.useWebhook);
    Vue.prototype.$session.set('erp_company_group_code', authData.erpCompanyGroupCode);

    Vue.prototype.$session.set('program_access-user_group', authData.userAccess.user_group);
    Vue.prototype.$session.set('program_access-advancepayments', authData.userAccess.advancepayments);
    Vue.prototype.$session.set('program_access-company', authData.userAccess.company);
    Vue.prototype.$session.set('program_access-user_group_access', authData.userAccess.user_group_access);
    Vue.prototype.$session.set('program_access-user', authData.userAccess.user);
    Vue.prototype.$session.set('program_access-block', authData.userAccess.block);
    Vue.prototype.$session.set('program_access-grouper', authData.userAccess.grouper);

    Vue.prototype.$session.set('user_access-change_date_tr', authData.userPermission.change_date_tr);
    Vue.prototype.$session.set('user_access-change_date_tp', authData.userPermission.change_date_tp);
    Vue.prototype.$session.set('user_access-change_date_pe', authData.userPermission.change_date_pe);
    Vue.prototype.$session.set('user_access-change_date_ad', authData.userPermission.change_date_ad);
    Vue.prototype.$session.set('user_access-register_addition_tr', authData.userPermission.register_addition_tr);
    Vue.prototype.$session.set('user_access-register_addition_tp', authData.userPermission.register_addition_tp);
    Vue.prototype.$session.set('user_access-register_decrease_tr', authData.userPermission.register_decrease_tr);
    Vue.prototype.$session.set('user_access-register_decrease_tp', authData.userPermission.register_decrease_tp);
    Vue.prototype.$session.set('user_access-change_payment_condition_tr', authData.userPermission.change_payment_condition_tr);
    Vue.prototype.$session.set('user_access-change_payment_condition_tp', authData.userPermission.change_payment_condition_tp);
    Vue.prototype.$session.set('user_access-change_exchange_rate_tr', authData.userPermission.change_exchange_rate_tr);
    Vue.prototype.$session.set('user_access-change_exchange_rate_tp', authData.userPermission.change_exchange_rate_tp);
    Vue.prototype.$session.set('user_access-change_interest_rate_tr', authData.userPermission.change_interest_rate_tr);
    Vue.prototype.$session.set('user_access-change_interest_rate_tp', authData.userPermission.change_interest_rate_tp);
    Vue.prototype.$session.set('user_access-change_amount_tr', authData.userPermission.change_amount_tr);
    Vue.prototype.$session.set('user_access-change_amount_tp', authData.userPermission.change_amount_tp);
    Vue.prototype.$session.set('user_access-change_amount_pe', authData.userPermission.change_amount_pe);
    Vue.prototype.$session.set('user_access-change_amount_sb', authData.userPermission.change_amount_sb);
    Vue.prototype.$session.set('user_access-include_comment_tr', authData.userPermission.include_comment_tr);
    Vue.prototype.$session.set('user_access-include_comment_tp', authData.userPermission.include_comment_tp);
    Vue.prototype.$session.set('user_access-include_comment_pe', authData.userPermission.include_comment_pe);
    Vue.prototype.$session.set('user_access-include_comment_sb', authData.userPermission.include_comment_sb);
    Vue.prototype.$session.set('user_access-include_comment_ad', authData.userPermission.include_comment_ad);
    Vue.prototype.$session.set('user_access-approve_tr', authData.userPermission.approve_tr);
    Vue.prototype.$session.set('user_access-approve_tp', authData.userPermission.approve_tp);
    Vue.prototype.$session.set('user_access-approve_pe', authData.userPermission.approve_pe);
    Vue.prototype.$session.set('user_access-approve_sb', authData.userPermission.approve_sb);
    Vue.prototype.$session.set('user_access-receive_message_box_closing', authData.userPermission.receive_message_box_closing);
    Vue.prototype.$session.set('user_access-configure_dynamic_groupers', authData.userPermission.configure_dynamic_groupers);
    Vue.prototype.$session.set('user_access-include_simulation', authData.userPermission.include_simulation);
    Vue.prototype.$session.set('user_access-view_dashboard_power_bi', authData.userPermission.view_dashboard_power_bi);
    Vue.prototype.$session.set('user_access-change_date_tr', authData.userPermission.change_date_tr);
    Vue.prototype.$session.set('user_access-change_date_tp', authData.userPermission.change_date_tp);
    Vue.prototype.$session.set('user_access-change_date_pe', authData.userPermission.change_date_pe);
    Vue.prototype.$session.set('user_access-change_date_ad', authData.userPermission.change_date_ad);
    Vue.prototype.$session.set('user_access-register_addition_tr', authData.userPermission.register_addition_tr);
    Vue.prototype.$session.set('user_access-register_addition_tp', authData.userPermission.register_addition_tp);
    Vue.prototype.$session.set('user_access-register_decrease_tr', authData.userPermission.register_decrease_tr);
    Vue.prototype.$session.set('user_access-register_decrease_tp', authData.userPermission.register_decrease_tp);
    Vue.prototype.$session.set('user_access-change_payment_condition_tr', authData.userPermission.change_payment_condition_tr);
    Vue.prototype.$session.set('user_access-change_payment_condition_tp', authData.userPermission.change_payment_condition_tp);
    Vue.prototype.$session.set('user_access-change_exchange_rate_tr', authData.userPermission.change_exchange_rate_tr);
    Vue.prototype.$session.set('user_access-change_exchange_rate_tp', authData.userPermission.change_exchange_rate_tp);
    Vue.prototype.$session.set('user_access-change_interest_rate_tr', authData.userPermission.change_interest_rate_tr);
    Vue.prototype.$session.set('user_access-change_interest_rate_tp', authData.userPermission.change_interest_rate_tp);
    Vue.prototype.$session.set('user_access-change_amount_tr', authData.userPermission.change_amount_tr);
    Vue.prototype.$session.set('user_access-change_amount_tp', authData.userPermission.change_amount_tp);
    Vue.prototype.$session.set('user_access-change_amount_pe', authData.userPermission.change_amount_pe);
    Vue.prototype.$session.set('user_access-change_amount_sb', authData.userPermission.change_amount_sb);
    Vue.prototype.$session.set('user_access-include_comment_tr', authData.userPermission.include_comment_tr);
    Vue.prototype.$session.set('user_access-include_comment_tp', authData.userPermission.include_comment_tp);
    Vue.prototype.$session.set('user_access-include_comment_pe', authData.userPermission.include_comment_pe);
    Vue.prototype.$session.set('user_access-include_comment_sb', authData.userPermission.include_comment_sb);
    Vue.prototype.$session.set('user_access-include_comment_ad', authData.userPermission.include_comment_ad);
    Vue.prototype.$session.set('user_access-approve_tr', authData.userPermission.approve_tr);
    Vue.prototype.$session.set('user_access-approve_tp', authData.userPermission.approve_tp);
    Vue.prototype.$session.set('user_access-approve_pe', authData.userPermission.approve_pe);
    Vue.prototype.$session.set('user_access-approve_sb', authData.userPermission.approve_sb);
    Vue.prototype.$session.set('user_access-receive_message_box_closing', authData.userPermission.receive_message_box_closing);
    Vue.prototype.$session.set('user_access-configure_dynamic_groupers', authData.userPermission.configure_dynamic_groupers);
    Vue.prototype.$session.set('user_access-include_simulation', authData.userPermission.include_simulation);
    Vue.prototype.$session.set('user_access-view_dashboard_power_bi', authData.userPermission.view_dashboard_power_bi);
    Vue.prototype.$session.set('user_access-view_billing_on_realized', authData.userPermission.view_billing_on_realized);
    Vue.prototype.$session.set('user_access-reconcile', authData.userPermission.reconcile);
    Vue.prototype.$session.set('user_access-include_pay_movement', authData.userPermission.include_pay_movement);
    Vue.prototype.$session.set('user_access-include_receive_movement', authData.userPermission.include_receive_movement);
    Vue.prototype.$session.set('user_access-write_off_receivables', authData.userPermission.write_off_receivables);
    Vue.prototype.$session.set('user_access-dda_conciliation_actions', authData.userPermission.dda_conciliation_actions);
    Vue.prototype.$session.set('user_access-approve_or_reprove_remittance', authData.userPermission.approve_or_reprove_remittance);
    Vue.prototype.$session.set('user_access-generate_invoice', authData.userPermission.generate_invoice);
    Vue.prototype.$session.set('user_access-is_billing_manager', authData.userPermission.is_billing_manager);
    Vue.prototype.$session.set('user_access-is_billing_operator', authData.userPermission.is_billing_operator);
    Vue.prototype.$session.set('user_access-chat_movidesk', authData.userPermission.chat_movidesk);
    Vue.prototype.$session.set('user_access-generate_advance', authData.userPermission.generate_advance);
    Vue.prototype.$session.set('user_access-generate_dda_account_payable', authData.userPermission.generate_dda_account_payable);
    Vue.prototype.$session.set('user_access-clear_bank_slip_data', authData.userPermission.clear_bank_slip_data);
    Vue.prototype.$session.set('user_access-show_specific_filters_from_account_payable', authData.userPermission.show_specific_filters_from_account_payable);

    Vue.prototype.$session.set('program_access-user_group', authData.userAccess.user_group);
    Vue.prototype.$session.set('program_access-advancepayments', authData.userAccess.advancepayments);
    Vue.prototype.$session.set('program_access-company', authData.userAccess.company);
    Vue.prototype.$session.set('program_access-user_group_access', authData.userAccess.user_group_access);
    Vue.prototype.$session.set('program_access-user', authData.userAccess.user);
    Vue.prototype.$session.set('program_access-block', authData.userAccess.block);
    Vue.prototype.$session.set('program_access-grouper', authData.userAccess.grouper);

    Vue.prototype.$session.set('user_config_autotoggle_paramns', authData.userConfig.autotoggle_paramns);
    Vue.prototype.$session.set('user_config_autotoggle_remittance_confirm_message', authData.userConfig.autotoggle_remittance_confirm_message);

    const authenticationModule: AuthenticationModule = getModule(AuthenticationModule);

    authenticationModule.changeCompanies(authData.userCompany);
    authenticationModule.changeUser({
      user_id: authData.id,
      token: authData.token,
      name: authData.name,
      email: authData.email,
      status: String(authData.status),
      user_group_id: String(authData.userGroupId),
      user_group_name: authData.userGroupName,
      user_type: authData.userType,
      company_group_id: String(authData.companyGroupId),
      company_group_name: String(authData.companyGroupName),
      user_gravatar: String(authData.userGravatar),
      company_group_image: String(authData.companyGroupImage),
      movidesk_id: String(authData.movideskId),
    });
  }
}
