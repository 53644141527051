import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import InvoiceList from '@/domain/models/InvoiceList';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import IListInvoiceFiltersRequest from '@/views/invoices/interfaces/IListInvoiceFiltersRequest';
import IDataTableWithLength from '@/domain/generic/IDataTableWithLength';
import InvoiceAccountsList from '@/domain/models/invoices/InvoiceAccountsList';

export default class RemittanceInvoiceRepository extends Repository {
  async getInvoicesByFilters(
    companyGroupId: number,
    companyIds: number[],
    params: ILaravelOrdenationTableParams<IListInvoiceFiltersRequest>,
  ): Promise<IDataTableWithLength<InvoiceList[]>> {
    const { data: response } = await this.datasource.get(
      `company-groups/${companyGroupId}/remittances/invoices`,
      {
        params,
        paramsSerializer: qs.stringify,
        headers: { 'company-ids': JSON.stringify(companyIds) },
      },
    );

    return {
      data: response.data.map(InvoiceList.make),
      total: response.total,
    };
  }

  async getAccountsFromInvoice(
    companyGroupId: number,
    invoiceId: number,
  ): Promise<any> {
    const { data: response } = await this.datasource.get(`company-groups/${companyGroupId}/remittances/invoices/${invoiceId}/accounts`);

    return response.data.map(InvoiceAccountsList.make);
  }
}
