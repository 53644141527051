









































import {
  Component,
  Ref,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import IPaymentRemittances from '@/domain/interfaces/IPaymentRemittances';
import IPaymentRemittancesFilter from '@/domain/interfaces/IPaymentRemittancesFilter';
import ISummary from '@/domain/interfaces/IPaymentRemittancesSummary';
import Summary from '@/domain/models/PaymentRemittancesSummary';
import ColumnToShow from '@/types/ColumnToShow';
import { formatErrorForNotification } from '@/utils/error';

import PaymentRemittanceModule from '@/stores/modules/PaymentRemittanceModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import PaymentRemittancesHeader from './components/Header.vue';
import PaymentRemittancesFilter from './components/Filter.vue';
import PaymentRemittancesSummary from './components/Summary.vue';
import PaymentRemittancesLists from './components/Lists.vue';
import { SummaryAction, SummaryActionData } from './utils/interfaces';
import { isStatusToApproveOrReprove } from './utils';

@Component({
  components: {
    PaymentRemittancesHeader,
    PaymentRemittancesFilter,
    PaymentRemittancesSummary,
    PaymentRemittancesLists,
  },
})
export default class PaymentRemittances extends Vue {
  @Ref('payment-remittances-filter') filter!: PaymentRemittancesFilter;

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  private readonly authenticationModule
    : AuthenticationModule = getModule(AuthenticationModule);
  private readonly paymentRemittanceModule
    : PaymentRemittanceModule = getModule(PaymentRemittanceModule);

  showFilter: boolean = true;
  showSummary: boolean = false;
  showList: boolean = false;

  list: Record<string, Array<PaymentRemittance>> = {};
  current: IPaymentRemittances = {} as IPaymentRemittances;

  summaries: Array<Summary> = [];

  columnsToShow: Array<ColumnToShow> = [];
  columnsToSummary: Array<ColumnToShow> = [];

  summaryActionData: SummaryActionData = {} as SummaryActionData;

  data: IPaymentRemittances = {
    dateInitial: '',
    dateEnd: '',
    companies: [],
    prefixes: [],
    paymentMethods: [],
    paymentData: [],
    bankTitles: [],
    bankSuppliers: [],
    search: '',
    valueMin: null,
    valueMax: null,
    status: [],
    bankSummaries: [],
    groupBy: [],
    type: '',
    supplierName: '',
    allCompanies: [],
    supplierIds: [],
    inclusionDates: [],
    riskTaken: [],
  };

  public created(): void {
    this.paymentRemittanceModule.setActualUserId(this.authenticationModule.user.user_id!);
  }

  formatIPaymentRemittancesFilter(data: IPaymentRemittances): IPaymentRemittancesFilter {
    return {
      initial_date: data.dateInitial,
      end_date: data.dateEnd,
      prefixes: data.prefixes,
      methods: data.paymentMethods,
      payment_data: data.paymentData,
      banks: data.bankTitles,
      bank_suppliers: data.bankSuppliers,
      search: data.search,
      value_min: data.valueMin,
      value_max: data.valueMax,
      status: data.status,
      group_by: data.groupBy,
      type: data.type,
      name: data.supplierName,
      supplier_ids: data.supplierIds,
      inclusion_dates: data.inclusionDates,
      risk_taken: data.riskTaken,
    };
  }

  async loadListAndSummary(data: IPaymentRemittances) {
    try {
      this.$dialog.startLoading();

      const group = this.$session.get('company_group_id');

      const params: IPaymentRemittancesFilter = this.formatIPaymentRemittancesFilter(data);

      if (data.bankSummaries.length) {
        const paramsToSummary: ISummary = {
          date_initial: data.dateInitial,
          date_end: data.dateEnd,
          banks: data.bankSummaries,
        };

        this.summaries = await this.paymentRemittanceRepository
          .getSummaries(
            group,
            data.companies.length
              ? data.companies
              : data.allCompanies,
            paramsToSummary,
          );
      }

      this.list = await this.paymentRemittanceRepository
        .getList(
          group,
          data.companies.length
            ? data.companies
            : data.allCompanies,
          params,
        );

      this.current = { ...data };

      this.showFilter = false;
      if (data.bankSummaries.length && this.summaries.length) this.showSummary = true;
      this.showList = true;
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  handleFilter() {
    if (!this.filter.validateFields()) return;
    this.summaries = [];
    this.list = {};
    this.showFilter = true;
    this.showList = false;
    this.showSummary = false;

    this.paymentRemittanceModule.resetIfHasChangedGroupingFilters();

    this.loadListAndSummary(this.data);
  }

  reload() {
    this.loadListAndSummary(this.current);
  }

  setColumnsToShow(columnsToShow: Array<ColumnToShow>) {
    this.columnsToShow = columnsToShow;
  }

  setColumnsToSummary(columnsToSummary: Array<ColumnToShow>) {
    this.columnsToSummary = columnsToSummary;
  }

  setColumns({
    columnsToShow,
    columnsToSummary,
  }: {
    columnsToShow: Array<ColumnToShow>,
    columnsToSummary: Array<ColumnToShow>,
  }) {
    this.columnsToShow = columnsToShow;
    this.columnsToSummary = columnsToSummary;
  }

  handleSummaryAction({ type, summary }: SummaryAction) {
    const { bank, agency, account } = summary;

    const itemsToAction = Object
      .keys(this.list)
      .flatMap((key) => this.list[key]
        .filter(
          (title) => (
            title.bank_code === bank
            && title.agency_code === agency
            && title.account_code === account
            && title.cnab_remi_generated_id
            && isStatusToApproveOrReprove(title.status)
          ),
      ));

    if (!itemsToAction.length) {
      this.$notification.warn('Nenhum título encontrado!');
      return;
    }

    this.summaryActionData = {
      type,
      items: itemsToAction,
    };
  }
}
