import DdaFiles from '@oldinncash/views/ddaupload/ddafiles.vue';
import ApiBankingConfigList from '@/views/apibankingconfig/List.vue';
import ApiBankingConfigSave from '@/views/apibankingconfig/Save.vue';
import BillingRemittance from '@/views/billingRemittance/List.vue';
import AcquittanceReason from '@/views/acquittanceReason/List.vue';
import AcquittanceReasonSave from '@/views/acquittanceReason/Save.vue';
import ApiBankingCredential from '@/views/apiBankingCredential/List.vue';
import ApiBankingCredentialSave from '@/views/apiBankingCredential/Save.vue';

import ClientList from '@/views/clients/ClientList.vue';
import Client from '@/views/clients/Client.vue';
import ClientGeneral from '@/views/clients/client/ClientGeneral.vue';
import ClientContacts from '@/views/clients/client/ClientContacts.vue';
import ClientInformation from '@/views/clients/client/ClientInformation.vue';
import ClientSells from '@/views/clients/client/ClientSells.vue';
import ClientHistory from '@/views/clients/client/ClientHistory.vue';
import FinancialStatement from '@/views/clients/components/FinancialStatement.vue';

import OrderList from '@/views/orders/OrderList.vue';
import Order from '@/views/orders/Order.vue';
import OrderAnalisys from '@/views/orders/order/OrderAnalisys.vue';
import OrderItems from '@/views/orders/order/OrderItems.vue';
import OrderHistory from '@/views/orders/order/OrderHistory.vue';

import CreditRulesList from '@/views/creditRules/CreditRulesList.vue';
import CreditRuleUpsert from '@/views/creditRules/CreditRuleUpsert.vue';

import CreditTotalizersList from '@/views/creditTotalizers/CreditTotalizersList.vue';
import CreditTotalizersUpsert from '@/views/creditTotalizers/CreditTotalizersUpsert.vue';

import Modules from '@/views/modules/Modules.vue';

import Schedule from '@/views/schedule/Schedule.vue';

import UserGroupList from '@/views/user_groups/UserGroupList.vue';
import UserGroupEdit from '@/views/user_groups/UserGroupEdit.vue';
import UserGroupCreate from '@/views/user_groups/UserGroupCreate.vue';
import UserGroupModules from '@/views/user_groups/UserGroupModules.vue';

import PayrollUpload from '@/views/payroll/PayrollUpload.vue';
import PayrollHistory from '@/views/payroll/PayrollHistory.vue';
import PayrollSelect from '@/views/payroll/PayrollSelect.vue';

import UserList from '@/views/users/UserList.vue';
import UserCreate from '@/views/users/UserCreate.vue';
import UserEdit from '@/views/users/UserEdit.vue';

import BillingMethodList from '@/views/billingMethod/BillingMethodList.vue';
import BillingMethodSave from '@/views/billingMethod/BillingMethodSave.vue';

import StatementConciliationList from '@/views/statementConciliation/StatementConciliationList.vue';
import StatementConciliationHistory from '@/views/statementConciliation/StatementConciliationHistory.vue';

import DdaConciliationList from '@/views/ddaConciliation/DdaConciliationList.vue';
import DdaConciliationHistory from '@/views/ddaConciliation/DdaConciliationHistory.vue';

import BillingRemittanceList from '@/views/billingRemittance/BillingRemittanceList.vue';

import OrderCustomer from '@/views/orders/order/OrderCustomer.vue';
import OrderCustomerSells from '@/views/orders/order/OrderCustomerSells.vue';

import SettledBankReturnScreen from '@/views/CnabRemittanceGenerated/SettledBankReturnScreen.vue';

import PaymentRemittances from '@/views/paymentRemittances/PaymentRemittances.vue';

import EconomicGroupList from '@/views/economicGroups/EconomicGroupList.vue';
import EconomicGroupView from '@/views/economicGroups/EconomicGroup.vue';

import OperatorDistributionList from '@/views/operatorDistribution/OperatorDistributionList.vue';
import OperatorDistributionView from '@/views/operatorDistribution/OperatorDistribution.vue';

import CompanySubscribersList from '@/views/companySubscribers/CompanySubscribersList.vue';
import CompanySubscribersView from '@/views/companySubscribers/CompanySubscribers.vue';

import BillingPendency from '@/views/billingPendencies/BillingPendency.vue';
import BillingPendencyList from '@/views/billingPendencies/BillingPendencyList.vue';
import BillingPendencyTransfers from '@/views/billingPendencies/BillingPendencyTransfers.vue';

import PreNegotiationList from '@/views/preNegotiation/PreNegotiationList.vue';
import CreatePreNegotiation from '@/views/preNegotiation/CreatePreNegotiation.vue';
import PreNegotiation from '@/views/preNegotiation/PreNegotiation.vue';

import InstructionsRemittance from '@/views/instructionsRemittance/InstructionsRemittance.vue';

import FinancialStatementBillingPendenciesEconomicGroup from '@/views/financialStatement/FinancialStatementBillingPendenciesEconomicGroup.vue';
import FinancialStatementBillingPendenciesCustomer from '@/views/financialStatement/FinancialStatementBillingPendenciesCustomer.vue';

import TradingPolicyList from '@/views/tradings/policies/TradingPolicyList.vue';
import TradingPolicyView from '@/views/tradings/policies/TradingPolicy.vue';

import NewsList from '@/views/news/NewsList.vue';
import UpsertNews from '@/views/news/components/UpsertNews.vue';

import InvoicesList from '@/views/invoices/InvoicesList.vue';

import MainGrouperCashFlowList from '@/views/cashFlowGroupers/mainGrouper/MainGrouperCashFlowList.vue';
import MainGrouperCashFlowUpsert from '@/views/cashFlowGroupers/mainGrouper/MainGrouperCashFlowUpsert.vue';

import PredictedFlow from '@/views/cashFlow/predictedFlow/PredictedFlow.vue';

export default [
  {
    path: '/apibankingconfig',
    name: 'APIConfigurations',
    component: ApiBankingConfigList,
  },
  {
    path: '/apibankingconfig/:service/type/:type/company/:company',
    name: 'APIConfigurationsEdit',
    component: ApiBankingConfigSave,
  },
  {
    path: '/apibankingconfig/:service/',
    name: 'APIConfigurationsSave',
    component: ApiBankingConfigSave,
  },
  {
    path: '/billingremittance/',
    name: 'BillingRemittance',
    component: BillingRemittance,
  },
  {
    path: '/ddafiles',
    name: 'DDAFiles',
    component: DdaFiles,
  },
  {
    path: '/clientes',
    component: ClientList,
    name: 'ClientList',
  },
  {
    path: '/clientes/:idCustomer',
    component: Client,
    children: [
      {
        path: '',
        component: FinancialStatement,
        name: 'ClientFinancialStatement',
      },
      {
        path: 'informacoes-gerais',
        component: ClientGeneral,
        name: 'ClientGeneral',
      },
      {
        path: 'contatos',
        component: ClientContacts,
        name: 'ClientContacts',
      },
      {
        path: 'informacoes-complementares',
        component: ClientInformation,
        name: 'ClientInformation',
      },
      {
        path: 'vendas',
        component: ClientSells,
        name: 'ClientSells',
      },
      {
        path: 'historico-de-cobranca',
        component: ClientHistory,
        name: 'ClientHistory',
      },
    ],
  },
  {
    path: '/pedidos',
    component: OrderList,
    name: 'OrderList',
  },
  {
    path: '/pedidos/:orderId/:idCustomer/:companyId',
    component: Order,
    children: [
      {
        path: '',
        component: OrderAnalisys,
        name: 'OrderAnalisys',
      },
      {
        path: 'ficha-financeira',
        component: FinancialStatement,
        name: 'OrderFinancialStatement',
      },
      {
        path: 'cliente',
        component: OrderCustomer,
        name: 'OrderCustomer',
      },
      {
        path: 'itens',
        component: OrderItems,
        name: 'OrderItems',
      },
      {
        path: 'vendas',
        component: OrderCustomerSells,
        name: 'OrderCustomerSells',
      },
      {
        path: 'historico-cobranca',
        component: OrderHistory,
        name: 'OrderHistory',
      },
    ],
  },
  {
    path: '/regras-de-credito',
    component: CreditRulesList,
    name: 'CreditRulesList',
  },
  {
    path: '/regras-de-credito/salvar',
    component: CreditRuleUpsert,
    name: 'CreditRuleUpsert',
  },
  {
    path: '/regras-de-credito/salvar/:id(\\d+)/:companyId(\\d+)',
    component: CreditRuleUpsert,
    name: 'CreditRuleUpsert',
  },
  {
    path: '/totalizadores-de-credito',
    component: CreditTotalizersList,
    name: 'CreditTotalizersList',
  },
  {
    path: '/totalizadores-de-credito/salvar',
    component: CreditTotalizersUpsert,
    name: 'CreditTotalizersUpsert',
  },
  {
    path: '/totalizadores-de-credito/salvar/:id(\\d+)/:companyId(\\d+)',
    component: CreditTotalizersUpsert,
    name: 'CreditTotalizersUpsert',
  },
  {
    path: '/modulos',
    component: Modules,
    name: 'Modules',
  },
  {
    path: '/agenda',
    component: Schedule,
    name: 'Schedule',
  },
  {
    path: '/grupos-de-usuario',
    component: UserGroupList,
    name: 'UserGroupList',
  },
  {
    path: '/grupos-de-usuario/novo',
    component: UserGroupCreate,
    name: 'UserGroupCreate',
  },
  {
    path: '/grupos-de-usuario/:groupId',
    component: UserGroupEdit,
    name: 'UserGroupEdit',
  },
  {
    path: '/grupos-de-usuario/:groupId/modulos/:moduleId(\\d+)',
    component: UserGroupModules,
    name: 'UserGroupModules',
  },
  {
    path: '/folhas-de-pagamento/enviar',
    name: 'PayrollUpload',
    component: PayrollUpload,
  },
  {
    path: '/folhas-de-pagamento/historico',
    name: 'PayrollHistory',
    component: PayrollHistory,
  },
  {
    path: '/folhas-de-pagamento/vincular',
    name: 'PayrollSelect',
    component: PayrollSelect,
  },
	{
		path: '/acquittancereason',
		name: 'AcquittanceReason',
		component: AcquittanceReason,

	},
	{
		path: '/acquittancereason/save',
		name: 'AcquittanceReasonSave',
		component: AcquittanceReasonSave,
	},
	{
		path: '/acquittancereason/save/:id',
		name: 'AcquittanceReasonUpdate',
		component: AcquittanceReasonSave,
	},
	{
		path: '/apibankingcredential',
		name: 'ApiBankingCredential',
		component: ApiBankingCredential,

	},
	{
		path: '/apibankingcredential/save',
		name: 'ApiBankingCredentialSave',
		component: ApiBankingCredentialSave,
	},
	{
		path: '/apibankingcredential/save/:id',
		name: 'ApiBankingCredentialUpdate',
		component: ApiBankingCredentialSave,
	},
  {
    path: '/usuarios',
    name: 'UserList',
    component: UserList,
  },
  {
    path: '/usuarios/novo',
    name: 'UserCreate',
    component: UserCreate,
  },
  {
    path: '/usuarios/:id',
    name: 'UserEdit',
    component: UserEdit,
  },
  {
    path: '/formas-de-recebimento',
    name: 'BillingMethodList',
    component: BillingMethodList,
  },
  {
    path: '/formas-de-recebimento/novo',
    name: 'BillingMethodCreate',
    component: BillingMethodSave,
  },
  {
    path: '/formas-de-recebimento/:id',
    name: 'BillingMethodUpdate',
    component: BillingMethodSave,
  },
  {
    path: '/conciliacoes-de-extrato',
    name: 'StatementConciliationList',
    component: StatementConciliationList,
  },
  {
    path: '/conciliacoes-de-extrato/historico',
    name: 'StatementConciliationHistory',
    component: StatementConciliationHistory,
  },
  {
    path: '/conciliacoes-de-dda',
    name: 'DdaConciliationList',
    component: DdaConciliationList,
  },
  {
    path: '/conciliacoes-de-dda/historico',
    name: 'DdaConciliationHistory',
    component: DdaConciliationHistory,
  },
  {
    path: '/remessas-de-cobranca',
    name: 'BillingRemittanceList',
    component: BillingRemittanceList,
  },
  {
		path: '/remessa-de-cnab-gerada/retorno-bancario/:remittanceId',
    name: 'SettledBankReturnScreen',
    component: SettledBankReturnScreen,
  },
  {
    path: '/remessas-de-pagamento',
    name: 'PaymentRemittances',
    component: PaymentRemittances,
  },
  {
    path: '/grupos-economicos',
    name: 'EconomicGroupList',
    component: EconomicGroupList,
  },
  {
    path: '/grupos-economicos/novo',
    component: EconomicGroupView,
    name: 'CreateEconomicGroup',
  },
  {
    path: '/grupos-economicos/:id',
    component: EconomicGroupView,
    name: 'EditEconomicGroup',
  },
  {
    path: '/distribuicao-de-operadores',
    component: OperatorDistributionList,
    name: 'OperatorDistributionList',
  },
  {
    path: '/distribuicao-de-operadores/nova',
    component: OperatorDistributionView,
    name: 'CreateOperatorDistribution',
  },
  {
    path: '/distribuicao-de-operadores/:id',
    component: OperatorDistributionView,
    name: 'EditOperatorDistribution',
  },
  {
    path: '/politicas-de-negociacao',
    component: TradingPolicyList,
    name: 'TradingPolicyList',
  },
  {
    path: '/politicas-de-negociacao/nova',
    component: TradingPolicyView,
    name: 'CreateTradingPolicy',
  },
  {
    path: '/politicas-de-negociacao/:id',
    component: TradingPolicyView,
    name: 'EditTradingPolicy',
  },
  {
    path: '/assinantes-por-empresa',
    component: CompanySubscribersList,
    name: 'CompanySubscribersList',
  },
  {
    path: '/assinantes-por-empresa/novo',
    component: CompanySubscribersView,
    name: 'CreateCompanySubscribers',
  },
  {
    path: '/assinantes-por-empresa/:id',
    component: CompanySubscribersView,
    name: 'EditCompanySubscribers',
  },
  {
    path: '/pendencias-de-cobranca',
    component: BillingPendency,
    children: [
      {
        path: '',
        component: BillingPendencyList,
        name: 'BillingPendencyList',
      },
      {
        path: 'transferencias',
        component: BillingPendencyTransfers,
        name: 'BillingPendencyTransfers',
      },
    ],
  },
  {
    path: '/ficha-financeira-grupo-economico/:economicGroupId',
    name: 'FinancialStatementBillingPendenciesEconomicGroup',
    component: FinancialStatementBillingPendenciesEconomicGroup,
  },
  {
    path: '/ficha-financeira-cliente/:origin/:idCustomerOrDocument',
    name: 'FinancialStatementBillingPendenciesCustomer',
    component: FinancialStatementBillingPendenciesCustomer,
  },
  {
    path: '/pre-negociacao',
    name: 'PreNegotiationList',
    component: PreNegotiationList,
  },
  {
    path: '/pre-negociacao/:preNegotiationId',
    name: 'PreNegotiation',
    component: PreNegotiation,
  },
  {
    path: '/pre-negociacao/criar/:groupIdentifierValue',
    name: 'CreatePreNegotiation',
    component: CreatePreNegotiation,
  },
  {
    path: '/remessa-de-instrucoes',
    name: 'InstructionsRemittance',
    component: InstructionsRemittance,
  },
  {
    path: '/noticias',
    name: 'NewsList',
    component: NewsList,
  },
  {
    path: '/noticias/criar',
    name: 'InsertNews',
    component: UpsertNews,
  },
  {
    path: '/noticias/atualizar/:id',
    name: 'UpdateNews',
    component: UpsertNews,
  },
  {
    path: '/faturas',
    name: 'InvoicesList',
    component: InvoicesList,
  },
  {
    path: '/grupo-principal-fluxo-caixa',
    name: 'MainGrouperCashFlowList',
    component: MainGrouperCashFlowList,
  },
  {
    path: '/grupo-principal-fluxo-caixa/criar',
    name: 'MainGrouperCashFlowCreate',
    component: MainGrouperCashFlowUpsert,
  },
  {
    path: '/grupo-principal-fluxo-caixa/atualizar/:id',
    name: 'MainGrouperCashFlowUpdate',
    component: MainGrouperCashFlowUpsert,
  },
  {
    path: '/fluxo-previsto',
    name: 'PredictedFlow',
    component: PredictedFlow,
  },
];
