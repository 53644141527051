

































































import { defineComponent } from '@vue/composition-api';
import ERPRepository from '@/repositories/ERPRepository';
import DialogHelper from '@/helpers/DialogHelper';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import { VForm } from '@/types/VForm';
import PredictedFlowActionsEnum from '@/views/cashFlow/predictedFlow/enums/PredictedFlowActionsEnum';
import ERPEventsEnum from '@/domain/enums/ERPEventsEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import ActionsWithoutReloadEnum from '@/views/cashFlow/predictedFlow/enums/ActionsWithoutReloadEnum';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IPredictedFlowGrouperItemData from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperItemData';
import IChangeDateExternalERPRequestParams from '@/views/cashFlow/predictedFlow/interfaces/actions/IChangeDateExternalERPRequestParams';

export default defineComponent({
  name: 'ChangeDateDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    companyGroupId: {
      required: true,
      type: Number,
    },
    register: {
      required: false,
      type: Object as () => IPredictedFlowGrouperList,
    },
    itemIndex: {
      required: false,
      type: Number,
    },
    totalIndex: {
      required: false,
      type: Number,
    },
  },
  computed: {
    dialogWidth(): string {
      return DialogHelper.getDialogWidth(this.$vuetify.breakpoint, {
        smAndDown: '100%',
        md: '60%',
        default: '40%',
      });
    },
    tableName(): string {
      return PredictedFlowHelper.getTableBasedOnNcc(this.register!, Boolean(this.$session.get('use_ncc')));
    },
    itemData(): IPredictedFlowGrouperItemData {
      return this.register!.itemData!;
    },
    grouperType(): CashFlowTablesEnum {
      return this.register!.grouperType;
    },
  },
  watch: {
    value(): void {
      this.isOpen = this.value;
    },
  },
  data() {
    const erpRepository: ERPRepository = new ERPRepository();

    const date: string = '';

    const loading: boolean = false;
    const isOpen: boolean = this.value;

    return {
      erpRepository,
      date,
      loading,
      isOpen,
    };
  },
  methods: {
    async changeDate(): Promise<void> {
      try {
        this.loading = true;

        const response = await Promise.allSettled([
          this.updateExternalERP(),
          this.updateInternalCustomerERP(),
          this.upsertInternalErp(),
        ]);

        if (!response.every(({ status }) => status === 'fulfilled')) {
          throw new Error();
        }

        this.$notification.success('Data alterada com sucesso!');

        this.$store.commit('predicted_flow/triggerEventSend', {
          action: ActionsWithoutReloadEnum.change_date,
          itemIndex: this.itemIndex,
          data: {
            value: this.date,
            totalIndex: this.totalIndex,
          },
        });

        this.close();
      } catch (error) {
        this.$notification.error('Houve um problema ao alterar a data.');
      } finally {
        this.loading = false;
      }
    },
    async updateExternalERP(): Promise<void> {
      await this.erpRepository.updateExternalERP<IChangeDateExternalERPRequestParams>(
        this.companyGroupId,
        this.itemData.companyId,
        {
          id: this.itemData.idCustomer,
          event: ERPEventsEnum.CASH_FLOW_DATE,
          data: {
            cash_flow: this.date.replaceAll('-', ''),
            type: this.tableName!,
          },
        },
      );
    },
    async updateInternalCustomerERP(): Promise<void> {
      const fieldBasedOnTable = this.grouperType === CashFlowTablesEnum.ACCOUNTS_RECEIVABLE ? 'e1_zdtflux' : 'e2_zdtflux';

      await this.erpRepository.updateInternalERP({
        id: this.itemData.id,
        field: fieldBasedOnTable,
        identify_values: PredictedFlowHelper.getTableAliasByGrouperType(this.grouperType),
        value: this.date,
      });
    },
    async upsertInternalErp(): Promise<void> {
      await this.erpRepository.saveDataInternalTable({
        _identifyValue: PredictedFlowHelper.getTableAliasByGrouperType(this.grouperType),
        _field: 'data',
        _date: this.itemData.date,
        _keyId: this.itemData.keyId,
        _data: this.date,
      });
    },
    close(): void {
      this.$emit('close', PredictedFlowActionsEnum.change_date);
    },
    handleChangeDate(): void {
      const changeDateForm = this.$refs.ChangeDateForm as VForm;

      if (!changeDateForm.validate()) {
        this.$notification.error('Há campos preenchidos incorretamente!');
        return;
      }

      this.changeDate();
    },
  },
});
