










































































































































import {
 Vue, Component, Prop, Emit, Watch, Ref,
} from 'vue-property-decorator';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import QrCodeReader from '@/components/defaults/QRCodeReader.vue';
import { VForm } from '@/types/VForm';
import KeyType from '@/domain/enums/PixKeyType';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { formatErrorForNotification } from '@/utils/error';

interface Data {
  type: number;
  key: string;
  keyType: KeyType | string;
  qrcode: string;
  bank: string;
  agency: string;
  agencyDigit: string;
  accountNumber: string;
  accountDigit: string;
  accountType: string;
}

interface IUpdateBankFields {
  bank: string|null,
  agency: string|null,
  accountNumber: string|null,
  accountType: string|null,
  agencyDigit: string|null,
  accountDigit: string|null,
}

@Component({
  components: {
    QrCodeReader,
  },
})
export default class PaymentRemittancesActionEditPix extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Object as () => PaymentRemittance,
  })
  readonly item!: PaymentRemittance;

  @Prop({
    type: Object as () => CompanyGroupConfig,
  })
  groupConfig!: CompanyGroupConfig;

  @Watch('item', { immediate: true })
  changedOpen(item: PaymentRemittance) {
    if (item) {
      const type = this.getPixTypeForField(item);

      let keyData = '';
      let qrCodeData = '';

      if (type === 1) {
        keyData = item.pixKey.trim().length > 0 ? item.pixKey.trim() : item.supplierPixKey.trim();
      } else if (type === 2) {
        qrCodeData = item.pixKey ?? '';
      }

      this.data = {
        type,
        key: keyData,
        keyType: '',
        qrcode: qrCodeData,
        bank: item.supplier_bank ?? '',
        agency: item.supplier_agency ?? '',
        agencyDigit: item.supplier_agency_digit ?? '',
        accountNumber: item.supplier_account_number ?? '',
        accountDigit: item.supplier_account_digit ?? '',
        accountType: item.supplier_account_type ?? '',
      };

      this.getKeyType(item.pixKey.length > 0 ? item.pixKey : item.supplierPixKey);

      this.oldPaymentType = type;
    }
  }

  @Emit()
  close(reload: boolean = false) {
    return reload;
  }

  readonly paymentRemittanceRepository: PaymentRemittanceRepository =
    new PaymentRemittanceRepository();

  readonly required = (value: string | number) => !!value || 'Campo obrigatório!';

  pixOptions: Array<ISelectOptions<number>> = [
    {
      text: 'Chave Pix',
      value: 1,
    },
    {
      text: 'QR Code',
      value: 2,
    },
    {
      text: 'Dados Bancários',
      value: 3,
    },
  ];

  accountTypes: Array<ISelectOptions<string>> = [
    {
      text: 'Conta Corrente',
      value: '1',
    },
    {
      text: 'Conta Poupança',
      value: '2',
    },
  ];

  data: Data = {
    type: 0,
    key: '',
    keyType: '',
    qrcode: '',
    bank: '',
    agency: '',
    agencyDigit: '',
    accountNumber: '',
    accountDigit: '',
    accountType: '',
  };

  oldPaymentType?: number;
  oldType?: string;

  onChangePaymentType() {
    if (this.data.type === 1) {
      this.data.keyType = '';
    }

    if (this.data.type === 2) {
      this.data.keyType = KeyType.RANDOM;
    }

    if (this.data.type === 3) {
      this.data.keyType = KeyType.BANK_DATA;
    }
  }

  getKeyType(pix: string): KeyType | string {
    let type: string = '';

    const key = this.getKeyWithoutFormattation(pix);

    if (this.data.type === 1 || this.data.type === 2) {
      const phoneRegex = /^55\d+$/;
      const randomRegex = /^[0-9a-fA-F]{32}$/;
      const number = new RegExp('^[0-9]+$', 'g');
      const isNumberAndNotPhone = number.test(key) && !phoneRegex.test(key);

      if (key.startsWith('+')) {
        type = 'PHONE';
        this.data.keyType = KeyType.PHONE;
      } else if (key.length >= 6 && key.length <= 11 && isNumberAndNotPhone) {
        type = 'CPF';
        this.data.keyType = KeyType.CPF;
      } else if (key.length >= 12 && key.length <= 14 && isNumberAndNotPhone) {
          type = 'CNPJ';
          this.data.keyType = KeyType.CNPJ;
      } else if (key.includes('@')) {
        type = 'EMAIL';
        this.data.keyType = KeyType.EMAIL;
      } else if (randomRegex.test(key)) {
        type = 'RANDOM';
        this.data.keyType = KeyType.RANDOM;
      } else {
        type = '';
        this.data.keyType = '';
      }
    } else {
      this.data.keyType = KeyType.BANK_DATA;
    }

    return type;
  }

  getKeyWithoutFormattation(key: string|null): string {
    if (key === null) {
      return '';
    }

    return key
      .replaceAll(' ', '')
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('/', '')
      .replaceAll('(', '')
      .replaceAll(')', '');
  }

  getPixTypeForField(item: PaymentRemittance): number {
    const definedPixType = item.pixType ?? item.supplierPixType;
    const randomKeyRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    switch (definedPixType) {
      case KeyType.PHONE: return 1;
      case KeyType.EMAIL: return 1;
      case KeyType.CNPJ: return 1;
      case KeyType.CPF: return 1;
      case KeyType.RANDOM:
        if (randomKeyRegex.test(item.pixKey)) {
          return 1;
        }

        return 2;
      case KeyType.BANK_DATA: return 3;
      default: return 1;
    }
  }

  get mask() {
    let mask: string | null = '';

    const type = this.getKeyType(this.data.key);

    switch (type) {
      case 'PHONE':
        mask = '+## (##) # ####-####';
        break;

      case 'CPF':
        mask = '###.###.###-###';
        break;

      case 'CNPJ':
        mask = '##.###.###/####-##';
        break;

      case 'RANDOM':
        if (
          this.data.key.includes('.')
          || this.data.key.includes('/')
        ) {
          this.data.key = this.getKeyWithoutFormattation(this.data.key);
        }

        mask = 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX';
        break;

      default:
        if (this.data.key) {
          this.data.key = this.data.key
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replaceAll('/', '')
            .replaceAll('(', '')
            .replaceAll(')', '');
        }

        if (this.oldType && ['CPF', 'CNPJ'].includes(this.oldType)) {
          this.data.key = this.data.key.replaceAll('.', '');
        }

        mask = '';
        break;
    }

    this.oldType = type;

    return mask;
  }

  get labelKeyType() {
    switch (this.data.keyType) {
      case KeyType.PHONE:
        return 'Telefone';
      case KeyType.CPF || KeyType.CNPJ:
        return this.keyTypeIsCPFOrCNPJ(this.data.key);
      case KeyType.EMAIL:
        return 'E-mail';
      case KeyType.RANDOM:
        return 'Aleatória';
      default:
        return '';
    }
  }

  validadePix() {
    const key = this.getKeyWithoutFormattation(this.data.key);

    switch (this.data.keyType) {
      case KeyType.PHONE:
        if (
          key.length !== 13
          && !/^\+55\d{11}$/.test(key)
        ) {
          this.$notification.warn('Telefone inválido!');
          return false;
        }
        break;
      case KeyType.CPF || KeyType.CNPJ: {
        const type = this.keyTypeIsCPFOrCNPJ(key);

        if (type === 'CPF' && key.length !== 11) {
          this.$notification.warn('CPF inválido!');
          return false;
        }

        if (type === 'CNPJ' && key.length !== 14) {
          this.$notification.warn('CNPJ inválido!');
          return false;
        }

        break;
      }
      case KeyType.EMAIL:
        if (!this.data.key.includes('@') || !this.data.key.includes('.')) {
          this.$notification.warn('E-mail inválido!');
          return false;
        }
        break;
      default:
        break;
    }

    return true;
  }

  save(): void {
    const formValidate = this.form.validate();

    if (!this.data.keyType) {
      this.$notification.warn('O valor da chave não pertence a nenhum tipo');

      return;
    }

    const validadePix = this.data.type === 1 ? this.validadePix() : true;

    if (formValidate && validadePix) {
      if (this.item.origin !== 'inncash') {
        this.handleUpdatePix(this.data, this.item);
      } else {
        this.handleUpdatePixInInvoice(this.data, this.item);
      }
    }
  }

  reset(): void {
    this.data = {
      type: 0,
      key: '',
      keyType: '',
      qrcode: '',
      bank: '',
      agency: '',
      agencyDigit: '',
      accountNumber: '',
      accountDigit: '',
      accountType: '',
    };

    this.form.resetValidation();
  }

  async handleUpdatePix(data: Data, item: PaymentRemittance): Promise<void> {
    try {
      this.$dialog.stopLoading();

      const { id: groupId, use_ncc: NCC } = this.groupConfig;
      const companyId = item.company_id;
      const companyIdFromSupplier = item.supplier_company_id;

      const useNCC = Boolean(NCC) && item.type === 'NCC';
      const isPixKey = data.type === 1;
      const isQRCode = data.type === 2;
      const isBankData = data.type === 3;

      if (this.oldPaymentType && this.oldPaymentType !== data.type) {
        await Promise.all(this.resetOldPaymentFieldsBasedOnType(this.oldPaymentType, data, item));
      }

      if (isPixKey) {
        const key = this.formatKeyToSend(data.key, data.keyType);

        const responseSupplier = await this.paymentRemittanceRepository.updateERP(
            groupId,
            companyIdFromSupplier,
            {
              id: item.supplier_id_customer,
              data: {
                type: useNCC ? 'customer' : 'provider',
                key,
                key_type: data.keyType,
              },
              event: 'pix_key',
            },
          );

        if (responseSupplier.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        const responseAccount = await this.paymentRemittanceRepository
          .updateERP(groupId, companyId, {
            id: item.id_customer,
            data: {
              type: useNCC ? 'account_receivable' : 'account_payable',
              key,
              key_type: data.keyType,
            },
            event: 'pix_key',
          });

        if (responseAccount.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        await Promise.all(this.updatePaymentFieldsPixRequests(key, item, data.keyType as KeyType));
      }

      if (isQRCode) {
        const response = await this.paymentRemittanceRepository.updateERP(
          groupId,
          companyId,
          {
            id: item.id_customer,
            data: {
              type: useNCC ? 'account_receivable' : 'account_payable',
              key: data.qrcode,
            },
            event: 'qrcode_key',
          },
        );

        if (response.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        await Promise.all(this.updatePaymentFieldsQrCodeRequests(data.qrcode, item, true));
      }

      if (isBankData) {
        const formatData: Record<string, string> = {
          type: useNCC ? 'customer' : 'provider',
          bank: data.bank,
          agency: data.agency,
          account_number: data.accountNumber,
          account_type: data.accountType,
          key_type: data.keyType,
        };

        if (this.groupConfig.use_digag) {
          formatData.agency_digit = data.agencyDigit;
        }

        if (this.groupConfig.use_digcta) {
          formatData.account_digit = data.accountDigit;
        }

        const response = await this.paymentRemittanceRepository.updateERP(
          groupId,
          companyIdFromSupplier,
          {
            id: item.supplier_id_customer,
            data: formatData,
            event: 'bank_data',
          },
        );

        if (response.error) {
          throw new Error('Ocorreu um erro na alteração do PIX!');
        }

        Promise.all(this.updatePaymentFieldsBankRequests(data, item, true));
      }

      this.close(true);
      this.$notification.success('PIX alterado com sucesso!');
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  resetOldPaymentFieldsBasedOnType(
    oldPaymentType: number,
    data: Data,
    item: PaymentRemittance,
  ): Promise<Function>[] {
    switch (oldPaymentType) {
      case 1:
        return this.updatePaymentFieldsPixRequests(
          null,
          item,
        );
      case 2:
        return this.updatePaymentFieldsQrCodeRequests(null, item);
      case 3:
        return this.updatePaymentFieldsBankRequests(
          {
            bank: null,
            agency: null,
            accountNumber: null,
            accountType: null,
            agencyDigit: null,
            accountDigit: null,
          },
          item,
        );
      default: return [];
    }
  }

  updatePaymentFieldsPixRequests(
    value: string|null,
    item: PaymentRemittance,
    keyType?: KeyType,
  ): Promise<Function>[] {
    const arrayUpdates = [
      this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SA2',
          id: item.supplier_id,
          field: 'A2_ZCHVPIX',
          value,
        },
      ),
      this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SE2',
          id: item.id,
          field: 'E2_ZCHVPIX',
          value,
        },
      ),
    ];

    if (keyType) {
      arrayUpdates.unshift(
        this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'SA2',
            id: item.supplier_id,
            field: 'A2_ZTIPPIX',
            value: keyType,
          },
        ),
      );
    }

    return arrayUpdates;
  }

  updatePaymentFieldsQrCodeRequests(
    value: string|null,
    item: PaymentRemittance,
    mustUpdateKeyType: boolean = false,
  ): Promise<Function>[] {
    const arrayRequests = [
      this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SE2',
          id: item.id,
          field: 'E2_ZCHVPIX',
          value,
        },
      ),
    ];

    if (mustUpdateKeyType) {
      arrayRequests.unshift(
        this.paymentRemittanceRepository.updateInternal(
          'updateImportTable',
          {
            identify_values: 'SA2',
            id: item.supplier_id,
            field: 'A2_ZTIPPIX',
            value: KeyType.RANDOM,
          },
        ),
      );
    }

    return arrayRequests;
  }

  updatePaymentFieldsBankRequests(
    data: IUpdateBankFields,
    item: PaymentRemittance,
    mustUpdateType: boolean = false,
  ): Promise<Function>[] {
    const supplierFields = [
      {
        field: 'A2_BANCO',
        value: data.bank,
      },
      {
        field: 'A2_AGENCIA',
        value: data.agency,
      },
      {
        field: 'A2_NUMCON',
        value: data.accountNumber,
      },
      {
        field: 'A2_TIPCTA',
        value: data.accountType,
      },
    ];

    if (mustUpdateType) {
      supplierFields.unshift({
        field: 'A2_ZTIPPIX',
        value: KeyType.BANK_DATA,
      });
    }

    if (this.groupConfig.use_digag) {
      supplierFields.push({
        field: 'A2_DVAGE',
        value: data.agencyDigit,
      });
    }

    if (this.groupConfig.use_digcta) {
      supplierFields.push({
        field: 'A2_DVCTA',
        value: data.accountDigit,
      });
    }

    return supplierFields.map((fields) => this.paymentRemittanceRepository.updateInternal(
      'updateImportTable',
      {
        identify_values: 'SA2',
        id: item.supplier_id,
        ...fields,
      },
    ));
  }

  async handleInternalUpdate(data: Data, item: PaymentRemittance) {
    const isPixKey = data.type === 1;
    const isQRCode = data.type === 2;
    const isBankData = data.type === 3;
    if (isPixKey) {
      const key = this.formatKeyToSend(data.key, data.keyType);

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SA2',
          id: item.supplier_id,
          field: 'A2_ZCHVPIX',
          value: key,
        },
      );

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SA2',
          id: item.supplier_id,
          field: 'A2_ZTIPPIX',
          value: data.keyType,
        },
      );

      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SE2',
          id: item.id,
          field: 'E2_ZCHVPIX',
          value: key,
        },
      );
    }

    if (isQRCode) {
      await this.paymentRemittanceRepository.updateInternal(
        'updateImportTable',
        {
          identify_values: 'SE2',
          id: item.id_customer,
          field: 'E2_ZCHVPIX',
          value: data.qrcode,
        },
      );
    }

    if (isBankData) {
      const supplierFields = [
        {
          field: 'A2_ZTIPPIX',
          value: KeyType.BANK_DATA,
        },
        {
          field: 'A2_BANCO',
          value: data.bank,
        },
        {
          field: 'A2_AGENCIA',
          value: data.agency,
        },
        {
          field: 'A2_NUMCON',
          value: data.accountNumber,
        },
        {
          field: 'A2_TIPCTA',
          value: data.accountType,
        },
      ];

      if (this.groupConfig.use_digag) {
        supplierFields.push({
          field: 'A2_DVAGE',
          value: data.agencyDigit,
        });
      }

      if (this.groupConfig.use_digcta) {
        supplierFields.push({
          field: 'A2_DVCTA',
          value: data.accountDigit,
        });
      }

      await Promise.all(
        supplierFields.map((fields) => this.paymentRemittanceRepository.updateInternal('updateImportTable', {
            identify_values: 'SA2',
            id: item.supplier_id,
            ...fields,
          })),
      );
    }
  }

  async handleUpdatePixInInvoice(data: Data, item: PaymentRemittance) {
    try {
      this.$dialog.startLoading();
      const { id: groupId } = this.groupConfig;
      const companyId = item.company_id;
      const isPixKey = data.type === 1;
      const isQRCode = data.type === 2;
      const isBankData = data.type === 3;

      let pixData = {};

      if (isPixKey) {
        const key = this.formatKeyToSend(data.key, data.keyType);

        pixData = {
          pix_key: key,
          key_type: data.keyType,
          type: 'pix',
        };
      }

      if (isQRCode) {
        pixData = {
          pix_key: data.qrcode,
          key_type: null,
          type: 'qrcode',
        };
      }

      if (isBankData) {
        pixData = {
          invoice_carrier: data.bank,
          invoice_deposit_agency: data.agency,
          invoice_account: data.accountNumber,
          account_type: data.accountType,
          key_type: KeyType.BANK_DATA,
          type: 'bank_data',
        };
      }

      await this.paymentRemittanceRepository.changeInvoice(
        groupId,
        companyId,
        item.id_customer,
        pixData,
      );

      await this.handleInternalUpdate(data, item);
      this.$dialog.stopLoading();
      this.close(true);
      this.$notification.success('PIX alterado com sucesso!');
    } catch (error: any) {
      this.$dialog.stopLoading();
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    }
  }

  formatKeyToSend(key: string, type: KeyType | string): string {
    if (type === KeyType.PHONE) {
      return key
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '');
    }

    if (type === KeyType.CPF || type === KeyType.CNPJ) {
      return this.getKeyWithoutFormattation(key);
    }

    return key;
  }

  keyTypeIsCPFOrCNPJ(key: string): 'CPF' | 'CNPJ' {
    return this.getKeyWithoutFormattation(key).length > 11 ? 'CNPJ' : 'CPF';
  }
}
