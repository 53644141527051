







































































































































































































































































































import {
  Component,
  Emit,
  Mixins,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import AccountPayableRepository from '@/repositories/AccountPayableRepository';

import CompanyGroupConfig from '@/domain/models/CompanyGroupConfig';

import PaymentRemittanceModule from '@/stores/modules/PaymentRemittanceModule';

import IPaymentRemittances from '@/domain/interfaces/IPaymentRemittances';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';

import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import PaymentData from '@/domain/enums/PaymentRemittancesDataType';
import GroupBy from '@/domain/enums/PaymentRemittancesGroupByType';
import Status from '@/domain/enums/PaymentRemittancesStatusType';
import Type from '@/domain/enums/PaymentRemittancesType';
import Name from '@/domain/enums/PaymentRemittancesNameType';

import { formatErrorForNotification } from '@/utils/error';
import { validateDateRange } from '@/utils/date';
import { VForm } from '@/types/VForm';
import FilterParameterPaymentRemittancesList from '@/domain/models/filter-parameters/FilterParameterPaymentRemittancesList';
import ColumnToShow from '@/types/ColumnToShow';

@Component
export default class PaymentRemittancesFilter
  extends Mixins<ModelManagementMixin<IPaymentRemittances>>(ModelManagementMixin) {
  @Ref('filter') readonly filter!: VForm;

  @Emit('set-columns')
  columnsToShow(columnsToShow: Array<ColumnToShow>, columnsToSummary: Array<ColumnToShow>) {
    return {
      columnsToShow,
      columnsToSummary,
    };
  }

  @Watch('suppliersSearch')
  changedSuppliersSearch(search: string) {
    if (search && search.length >= 3) {
      this.debounceSuppliers(search);
    } else {
      clearTimeout(this.suppliersTimer);
    }
  }

  readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();
  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();
  readonly accountPayableRepository:
    AccountPayableRepository = new AccountPayableRepository();

  private readonly paymentRemittanceModule
    : PaymentRemittanceModule = getModule(PaymentRemittanceModule);

  valueMinPrefix = false;
  valueMaxPrefix = false;
  methodsLoading = false;
  prefixesLoading = false;
  bankAccountsLoading = false;
  errorDateRange = false;

  bankAccountsSearch = '';
  bankAccountsSearchTimer!: ReturnType<typeof setTimeout>;

  setBankAccounts = new Set();

  groupConfig: CompanyGroupConfig = {} as CompanyGroupConfig;

  companies: Array<ISelectOptions<number>> = [];
  paymentMethods: Array<ISelectOptions<string>> = [];
  banks: Array<ISelectOptions<string>> = [];
  prefixes: Array<ISelectOptions<string>> = [];
  bankAccounts: Array<ISelectOptions<string>> = [];
  suppliers: Array<ISelectOptions<string>> = []
  inclusionDateItems: string[] = [];
  riskTakenItems: string[] = [];

  status: Array<ISelectOptions<string>> = [
    {
      text: 'Não Enviado',
      value: Status.NOT_SENT,
    },
    {
      text: 'Enviado',
      value: Status.SENT,
    },
    {
      text: 'Aguardando Aprovação',
      value: Status.PENDING,
    },
    {
      text: 'Aprovado Parcialmente',
      value: Status.PARTIALLY_APPROVED,
    },
    {
      text: 'Aprovado',
      value: Status.APPROVED,
    },
    {
      text: 'Não Aprovado',
      value: Status.DISAPPROVED,
    },
  ];

  groupBy: Array<ISelectOptions<string>> = [
    {
      text: 'Empresa',
      value: GroupBy.COMPANY,
    },
    {
      text: 'Forma de Pagamento',
      value: GroupBy.METHOD,
    },
    {
      text: 'Portador',
      value: GroupBy.BANK,
    },
    {
      text: 'Fornecedor',
      value: GroupBy.SUPPLIER,
    },
    {
      text: 'Natureza',
      value: GroupBy.NATURE,
    },
  ];

  paymentData: Array<ISelectOptions<string>> = [
    {
      text: 'Preenchido',
      value: PaymentData.FILLED,
    },
    {
      text: 'Em Branco',
      value: PaymentData.EMPTY,
    },
  ];

  type: Array<ISelectOptions<string>> = [
    {
      text: 'Analítico',
      value: Type.ANALYTICAL,
    },
    {
      text: 'Sintético',
      value: Type.SYNTHETIC,
    },
  ];

  supplierName: Array<ISelectOptions<string>> = [
    {
      text: 'Nome Fantasia',
      value: Name.TRADE,
    },
    {
      text: 'Razão Social',
      value: Name.CORPORATE,
    },
  ];

  suppliersItems: Array<ISelectOptions> = [];
  suppliersTimer!: ReturnType<typeof setTimeout>;
  suppliersSearch: string = '';
  suppliersLoading: boolean = false;

  requeridMultipleSelect = (value: Array<any>) => value.length > 0 || 'Campo obrigatório!';

  get mustShowSpecificFilters(): number {
    return Number(this.$session.get('user_access-show_specific_filters_from_account_payable'));
  }

  get suppliersNoDataText(): string {
    let noDataText = 'Digite 3 ou mais caracteres.';

    if ((this.suppliersSearch?.length >= 3) || (this.suppliersItems.length > 0)) {
      noDataText = this.suppliersLoading
        ? 'Carregando clientes...'
        : 'Nenhum cliente correspondente encontrado.';
    }

    return noDataText;
  }

  debounceSuppliers(search: string): void {
    clearTimeout(this.suppliersTimer);
    this.suppliersTimer = setTimeout(() => {
      this.handleLoadSuppliers(search);
    }, 500);
  }

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  get bankAccountsNoData(): string {
    let text = 'Digite para buscar.';

    if ((this.bankAccountsSearch?.length >= 3) || (this.bankAccounts.length > 0)) {
      text = this.bankAccountsLoading
        ? 'Buscando conta(s)...'
        : 'Nenhuma conta correspondente encontrada.';
    }

    return text;
  }

  async handleLoadSuppliers(search: string): Promise<void> {
    try {
      this.suppliersLoading = true;
      await this.loadSuppliers(search);
    } catch (error) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.suppliersLoading = false;
    }
  }

  async loadSuppliers(search: string): Promise<void> {
    const suppliers = await this.paymentRemittanceRepository
      .getSuppliers(this.groupId, this.companies.map((company) => company.value), search, false);
    this.suppliersItems.push(...suppliers);
  }

  mounted() {
    this.loadOptions();
  }

  togglePrefix(event: FocusEvent, prefix: boolean, value: number | null) {
    if (event.type === 'focus' && prefix !== true) {
      return true;
    }

    if (event.type === 'blur' && value === null) {
      return false;
    }

    return prefix;
  }

  togglePrefixOfValueMin(event: FocusEvent) {
    this.valueMinPrefix = this.togglePrefix(event, this.valueMinPrefix, this.model.valueMin);
  }

  togglePrefixOfValueMax(event: FocusEvent) {
    this.valueMaxPrefix = this.togglePrefix(event, this.valueMaxPrefix, this.model.valueMax);
  }

  debounceBankAccounts(search: string): void {
    clearTimeout(this.bankAccountsSearchTimer);

    this.bankAccountsSearchTimer = setTimeout(() => {
      this.handleLoadBankAccounts(search);
    }, 500);
  }

  validateRange(): boolean {
    const { dateInitial, dateEnd } = this.model;

    const isEmissionDateValid = validateDateRange(dateInitial, dateEnd);

    if (!isEmissionDateValid) {
      this.errorDateRange = true;
      this.$notification.error('Intevalo de emissão inválido!');
    } else {
      this.errorDateRange = false;
    }

    return isEmissionDateValid;
  }

  validateFields(): boolean {
    const isValidDateRange = this.validateRange();
    const isValidFilter = this.filter.validate();

    if (isValidDateRange && isValidFilter) {
      this.filterParametersRepository.setFilter(GroupFilterParametersEnum.PAYMENT_REMITTANCES, [
        { key: 'date_initial_payment_remittances_list', value: this.model.dateInitial },
        { key: 'date_end_payment_remittances_list', value: this.model.dateEnd },
        { key: 'companies_payment_remittances_list', value: JSON.stringify(this.model.companies) },
        { key: 'payment_methods_remittances_list', value: JSON.stringify(this.model.paymentMethods) },
        { key: 'prefixes_remittances_list', value: JSON.stringify(this.model.prefixes) },
        { key: 'payment_data_payment_remittances_list', value: JSON.stringify(this.model.paymentData) },
        { key: 'bank_titles_payment_remittances_list', value: JSON.stringify(this.model.bankTitles) },
        { key: 'bank_suppliers_payment_remittances_list', value: JSON.stringify(this.model.bankSuppliers) },
        { key: 'search_payment_remittances_list', value: this.model.search },
        { key: 'value_min_payment_remittances_list', value: this.model.valueMin },
        { key: 'value_max_payment_remittances_list', value: this.model.valueMax },
        { key: 'status_payment_remittances_list', value: JSON.stringify(this.model.status) },
        { key: 'bank_summaries_payment_remittances_list', value: JSON.stringify(this.model.bankSummaries) },
        { key: 'group_by_payment_remittances_list', value: JSON.stringify(this.model.groupBy) },
        { key: 'type_payment_remittances_list', value: this.model.type },
        { key: 'supplier_name_payment_remittances_list', value: this.model.supplierName },
        { key: 'inclusion_dates_payment_remittances_list', value: JSON.stringify(this.model.inclusionDates) },
        { key: 'risk_taken_payment_remittances_list', value: JSON.stringify(this.model.riskTaken) },
      ]);

      return true;
    }

    return false;
  }

  async loadCompanies(): Promise<void> {
    this.companies = await this.paymentRemittanceRepository
      .getCompanies(this.groupId);

    this.model.allCompanies = this.companies.map((company) => company.value);
  }

  async loadMethods(companies: Array<number>): Promise<void> {
    this.paymentMethods = await this.paymentRemittanceRepository
      .getMethods(this.groupId, companies.length ? companies : this.model.allCompanies);

    this.paymentMethods.push({
      text: 'Em Branco',
      value: PaymentData.EMPTY,
    });
  }

  async handleUpdateNewDataOnChange(companies: Array<number>) {
    try {
      this.methodsLoading = true;
      this.prefixesLoading = true;

      await Promise.all([
        this.loadMethods(companies),
        this.loadRemittanceAccountPayablePrefixes(companies),
      ]);
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.methodsLoading = false;
      this.prefixesLoading = false;
    }
  }

  async loadBanks(): Promise<void> {
    const banks = await this.paymentRemittanceRepository.getBanks(this.groupId);

    this.banks = [
      {
        text: 'Em Branco',
        value: 'empty',
      },
      ...banks.map(({ text, value }) => ({
        text,
        value,
      })),
    ];
  }

  async loadRemittanceAccountPayablePrefixes(companyIds: Array<number>): Promise<void> {
    this.prefixes = await this.paymentRemittanceRepository.getPrefixes(
      this.groupId,
      companyIds.length ? companyIds : this.model.allCompanies,
      );
  }

  async loadBankAccounts(companies: Array<number>, search?: string): Promise<void> {
    if (!search) return;

    const accounts = await this.paymentRemittanceRepository
      .getBankAccounts(
        this.groupId,
        companies.length ? companies : this.model.allCompanies,
        search,
      );

    const concat = [...this.bankAccounts, ...accounts];

    this.bankAccounts.push(...concat.filter((account) => {
      const duplicated = this.setBankAccounts.has(account.value);
      this.setBankAccounts.add(account.value);
      return !duplicated;
    }));
  }

  async loadInclusionDates(): Promise<void> {
    this.inclusionDateItems = await this.accountPayableRepository
      .getInclusionDateOptions(this.groupId);
  }

  async loadRisksTaken(): Promise<void> {
    this.riskTakenItems = await this.accountPayableRepository.getRiskTakenOptions(this.groupId);
  }

  async handleLoadBankAccounts(search: string) {
    try {
      this.bankAccountsLoading = true;

      await this.loadBankAccounts(this.model.companies, search);
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.bankAccountsLoading = false;
    }
  }

  async loadGroupConfig() {
    this.groupConfig = await this.paymentRemittanceRepository
      .getGroupConfig(this.groupId);
  }

  async loadOptions(): Promise<void> {
    try {
      this.$dialog.startLoading();

      await this.loadFilterParameters();
      await this.loadCompanies();

      const promises = [
        this.loadRemittanceAccountPayablePrefixes(this.model.companies),
        this.loadGroupConfig(),
        this.loadMethods(this.model.companies),
        this.loadBanks(),
        this.loadBankAccounts(this.model.companies, 'banco'),
      ];

      if (this.mustShowSpecificFilters) {
        promises.push(this.loadInclusionDates());
        promises.push(this.loadRisksTaken());
      }

      await Promise.all(promises);
    } catch (error) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  async loadFilterParameters(): Promise<void> {
    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.PAYMENT_REMITTANCES);

      const { columnsToShow, columnsToSummary, ...params } = FilterParameterPaymentRemittancesList
        .make(filterParameters);

      if (!this.mustShowSpecificFilters) {
        params.inclusionDates = [];
        params.riskTaken = [];
      }

      this.model = { ...params, allCompanies: [] };
      this.columnsToShow(columnsToShow, columnsToSummary);
      this.paymentRemittanceModule.setSelectedGroupPaymentFilters(this.model.groupBy);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    }
  }

  public handleGroupingPaymentsBy(): void {
    this.paymentRemittanceModule.setSelectedGroupPaymentFiltersChange(this.model.groupBy);
  }
}
