import axios, { AxiosError } from 'axios';

export function formateErrorForNotification(error: Error | AxiosError): string {
  let returnMessage = '';
  const errorMessageOrName = error.message ? error.message : error.name;

  if (axios.isAxiosError(error)) {
    const { message, errors } = error.response?.data
      || { message: errorMessageOrName, erros: null };

    if (typeof errors === 'object' && errors !== null) {
      const errorsStringify = Object.entries(errors)
        .map(([key, value]) => (value == message ? '' : value)).join('\n');
      returnMessage = `${message} \n\n ${errorsStringify}`;
    } else {
      returnMessage = message;
    }
  } else {
    returnMessage = errorMessageOrName;
  }
  console.error(error);
  return returnMessage;
}

export function formateCNPJ(value: string): string {
  if (typeof value !== 'string') return 'CNPJ Inválido';
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5',
  );
}

export function formateCurrency(value: number): string {
  if (typeof value !== 'number') return 'Valor Inválido';

  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });
}

export function scrollTo(elementId: string): void {
  const doc = document.getElementById(elementId) || {} as HTMLElement;
  doc.scrollIntoView({ behavior: 'smooth' });
}
