



































































import ActionType from '@/domain/enums/PaymentRemittancesActionType';
import PaymentRemittance from '@/domain/models/PaymentRemittance';
import PaymentRemittanceRepository from '@/repositories/PaymentRemittanceRepository';
import { VForm } from '@/types/VForm';
import { formatErrorForNotification } from '@/utils/error';
import {
  Vue,
  Component,
  Prop,
  Emit,
  Ref,
  Watch,
} from 'vue-property-decorator';
import { CloseActionData } from '../../utils/interfaces';

@Component
export default class PaymentRemittancesActionAddComment extends Vue {
  @Ref('form') readonly form!: VForm;

  @Prop({
    type: Boolean,
  }) readonly open!: boolean;

  @Prop({
    type: Object as () => PaymentRemittance,
  }) readonly item!: PaymentRemittance;

  @Emit()
  close(reload = false): CloseActionData {
    return { type: ActionType.ADD_COMMENT, reload };
  }

  @Watch('open')
  onChangeOpen(value: boolean) {
    if (value) {
      if (this.item && this.item.comment) this.comment = this.item.comment;
    } else {
      this.comment = '';
      this.loading = false;
      this.form.resetValidation();
    }
  }

  readonly paymentRemittanceRepository:
    PaymentRemittanceRepository = new PaymentRemittanceRepository();

  readonly required = (v: string) => !!v || 'Campo obrigatório!';
  comment: string = '';
  loading: boolean = false;

  get isInvoice(): boolean {
    return this.item
      && this.item.origin === 'inncash'
      && this.item.type === 'FT';
  }

  save(): void {
    if (this.loading) return;

    const isValidform = this.form.validate();

    if (isValidform) {
      this.handleSave(this.comment);
    }
  }

  async handleSave(comment: string) {
    if (this.item.comment.length && comment === this.item.comment) {
      this.$notification.warn('Comentário já existente! Informe um novo.');
      return;
    }

    try {
      this.loading = true;

      const group = this.$session.get('company_group_id');
      const company = this.item.company_id;
      const id = this.item.id_customer;
      const type = this.isInvoice ? 'invoices' : 'accounts-payable';

      await this.paymentRemittanceRepository
        .comment(group, company, id, type, comment);

      this.item.comment = comment;

      this.$notification.success('Comentário salvo com sucesso!');
      this.close();
    } catch (error: any) {
      const message = formatErrorForNotification(error);
      this.$notification.error(message);
    } finally {
      this.loading = false;
    }
  }
}
