<template>
	<v-badge
		:color="badge.color"
		:value="badge.show"
		class="notification-badge"
		overlap
		dot
	>
		<v-menu>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					title="Notificações"
					icon
					large
					v-on="on"
					v-bind="attrs"
					@click="onClickNotifications"
				>
					<v-icon>
						mdi-bell-outline
					</v-icon>
				</v-btn>
			</template>

			<notification-area />
		</v-menu>
	</v-badge>
</template>

<script>
import NotificationArea from '@/components/notifications/NotificationArea.vue';

export default {
	name: 'NotificationsMenu',
	components: {
		NotificationArea,
	},
	data: () => ({
		badge: {
			value: false,
			color: 'success',
		},
		notifications: [],
	}),
	computed: {
		notificationList() {
			return [];
		},
	},
	watch: {
		notificationList() {
			this.checkNotificationWarn();
		},
	},
	methods: {
		onClickNotifications() {
			this.setAsReadNotifications();
		},
		setAsReadNotifications() {
			this.badge = {
				show: false,
				color: 'success',
			};
		},
		checkNotificationWarn() {
			this.defineBadges(null);
		},
		defineBadges(status) {
			if (!status || status == 'none') {
				this.badge = {
					show: false,
					color: 'success',
				};

				return;
			}

			if (status == 'error') {
				this.badge = {
					show: true,
					color: 'error',
				};

				return;
			}

			this.badge = {
				show: true,
				color: 'warning',
			};
		},
	},
};
</script>

<style scoped>

.in-btn_sync {
	transform: translate(-55px, 40px);
	position: absolute;
	color: #757575;
}

.v-menu__content {
	top: 50px !important;
	max-width: 440px;
}

.v-list-item__icon {
	margin: 16px 16px !important;
}
</style>
